import { Controller } from "stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import ahoy from "ahoy.js"

export default class extends Controller {
  productSlider = null

  static values = {
    uid: String,
    sku: String,
    slug: String,
    variation: String,
    ringsize: String,
    europrice: String,
    url: String,
    name: String,
    image: String,
    producturl: String,
    shippingDelayUrl: String,
    collection: String
  }

  static targets = ["addToCartButton", "zoomContainer", "shippingDelay"]

  connect() {
    this.swiperInstance = null
    this.swiperContainer = document.querySelector('[data-swiper-product-grid]')
    this.initializeSwiper()
    this.initializeShippingDelay()

    this.resized()

    // this.slug = this.addToCartButtonTarget.dataset.slug

    this.trackAnalytics()

  }

  disconnect() {
    this.swiperInstance.destroy()
  }

  resized() {
    if (this.swiperInstance === null) { return }
    if (document.body.clientWidth < 768) {
      if (this.swiperInstance.destroyed == true) {
        this.initializeSwiper()
      }
    } else {
      if (this.swiperInstance.enabled == true) {
        this.swiperInstance.destroy()
      }
    }
  }

  initializeSwiper() {
    if (this.swiperContainer === null) { return }
    this.swiperInstance = new Swiper(this.swiperContainer, {
      modules: [Navigation, Pagination],
      loop: true,
      enabled: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  // initializeZoomSlider() {

  //   const zoomSliderContainer = document.querySelector('[data-slider-zoom]')
  //   const zoomSliderThumbnails = document.querySelectorAll('[data-slider-zoom-thumbnail]')

  //   if (zoomSliderContainer) {
  //     this.zoomSlider = tns({
  //       container: zoomSliderContainer,
  //       items: 1,
  //       slideBy: 1,
  //       mode: "gallery",
  //       edgePadding: 0,
  //       gutter: 0,
  //       autoplay: false,
  //       lazyload: true,
  //       controls: false,
  //       mouseDrag: false,
  //       touch: false,
  //       navContainer: ".zoom-thumbnails",
  //       navAsThumbnails: true,
  //       speed: 0,
  //       // preventScrollOnTouch: "auto",
  //       // swipeAngle: 30,
  //     })
  //   }
  // }

  // sliderEvent(params) {
  //   Analytics.eventAmplitudeAdvanced('Product Info', 'Slider', {
  //     'slug': this.slugValue,
  //     'eventName': params['eventName'],
  //     'index': params['index']
  //   });
  // }

  // zoomIn(e) {
  //   if (this.hasZoomContainerTarget) {
  //     // let activeIndex = this.productSlider.getInfo().index - 1
  //     let clickedImage = e.params.image
  //     this.zoomSlider.goTo(clickedImage)
  //     this.zoomContainerTarget.classList.remove('hidden')
  //     let w = parseFloat(this.zoomContainerTarget.getBoundingClientRect().width)
  //     this.zoomContainerTarget.scrollTo(w/2, 0)
  //     let device = window.innerWidth < 768 ? 'mobile' : 'desktop'
  //     Analytics.eventGa('Zoom In', device, this.slugValue)
  //   }
  // }

  // zoomOut() {
  //   if (this.hasZoomContainerTarget) {
  //     // let activeIndex = this.zoomSlider.getInfo().index
  //     // this.productSlider.goTo(0)
  //     this.zoomContainerTarget.classList.add('hidden')
  //     let device = window.innerWidth < 768 ? 'mobile' : 'desktop'
  //     Analytics.eventGa('Zoom Out', device, this.slugValue)
  //   }
  // }

  initializeShippingDelay() {

    if (this.shippingDelayUrlValue === '') { return }

    let data = { "product": this.slugValue, "variation": this.variationValue, "size": this.ringsizeValue }
    let token = document.querySelector("[name='csrf-token']").content

    this.shippingDelayTargets.forEach(target => {
      target.innerHTML = '<div class="text-center flex justify-center" style="height: 44px"><img src="/loading.svg" style="height:76px; margin-top: -20px; max-height: auto"></div>'
    })


    fetch(this.shippingDelayUrlValue, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((r) => { if (!r.ok) { this.application.notify(r.statusText) } return r })
    .then((r) => r.text())
    .then((html) => {
      const fragment = document
        .createRange()
        .createContextualFragment(html);
      // this.shippingDelayTarget.innerHTML = html
      this.shippingDelayTargets.forEach(target => {
        target.innerHTML = html
      })
      MicroModal.init({
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
        disableScroll: true,
        disableFocus: true,
        onShow: (modal) => {
          // Analytics
          Analytics.eventGa('Modal', modal.id, '', null, true);
          Analytics.eventAmplitudeAdvanced('Modal', null, {'type': modal.id});
        }
      })
    })
    .catch((error) => {
      window.application.notify(error)
    })

  }

  updateShippingDelay(event) {
    let target = event.target
    this.ringsizeValue = target.dataset.ringsize
    this.initializeShippingDelay()
  }

  trackAnalytics() {
    const name = this.nameValue
    const sku = this.skuValue
    const slug = this.slugValue
    const variation = this.variationValue
    const skuvariation = sku + (variation === "" ? "" : "-" + variation.toUpperCase())
    const image = this.imageValue
    const europrice = this.europriceValue
    const producturl = this.producturlValue
    const collection = this.collectionValue
    const fullslug = [slug, variation].filter(Boolean).join('-')

    // Analytics.eventGa('Product Info', sku, europrice);
    Analytics.eventAmplitudeAdvanced('Product Info', null, {
      'sku': sku,
      'slug': slug,
      'variation': variation,
      'price': europrice,
      'collection': collection
    });
    FacebookConversion.viewContent(skuvariation, europrice, this.uidValue);
    Analytics.productInfo({
      "currency": "EUR",
      "value": parseFloat(europrice),
      "items": [{
        // "item_id": sku,
        "item_name": fullslug,
        "price": parseFloat(europrice)
      }]
    })
    Klaviyo.viewedProduct({
      "price": europrice,
      "name": name,
      "slug": slug,
      "skuvariation": skuvariation,
      "image": image,
      "url": producturl,
      "price": europrice
    });

    // Ahoy
    ahoy.track("viewed product", {slug: slug, fullslug: fullslug})

  }


  // offset(el) {
  //   let rect = el.getBoundingClientRect()
  //   let scrollLeft = window.scrollX || document.documentElement.scrollLeft
  //   let scrollTop = window.scrollY || document.documentElement.scrollTop
  //   return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  // }

}
