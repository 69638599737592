import { Controller } from "stimulus"

import {hideAll} from 'tippy.js'

export default class extends Controller {

  static targets = ["inlineButton"]

  connect() {
    // https://medium.com/elegant-seagulls/parallax-and-scroll-triggered-animations-with-the-intersection-observer-api-and-gsap3-53b58c80b2fa
    let options = {
      root: null,
      rootMargin: '0px 0px',
      threshold: 0
    }
    if (this.hasInlineButtonTarget) {
      let target = this.inlineButtonTarget
      this.inlineButtonTargets.forEach( btn => {
        if ('IntersectionObserver' in window) {
          let observer = new IntersectionObserver(this.showSticky, options)
          observer.observe(btn)
        }
      })
    }
  }

  disconnect() {
  }

  showSticky(entry) {
    if (entry[0].target === undefined) { return }
    if (entry[0].target.offsetParent == null) { return }

    let sticky = document.querySelector('#product-sticky')
    if (entry[0].intersectionRatio > 0) {
      // console.log('Button is shown')
      hideAll()
      sticky.classList.add('-translate-y-full')
      sticky.classList.remove('shadow-xl')
      sticky.classList.remove('pointer-events-auto')
    } else {
      // console.log('Button is out of the screen')
      sticky.classList.remove('-translate-y-full')
      sticky.classList.add('shadow-xl')
      sticky.classList.add('pointer-events-auto')
    }
  }

}