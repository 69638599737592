// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("classes/micromodal")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers/application"

import  "../layouts/application.css"

import LazyLoad from "vanilla-lazyload"
// import MicroModal from 'micromodal' // Using local modified copy
import Headroom from "headroom.js"
import ahoy from "ahoy.js"

import Analytics from "classes/analytics.js"
import FacebookConversion from "classes/facebook.js"
import Klaviyo from "classes/klaviyo.js"


document.addEventListener("DOMContentLoaded", function() {

  document.lazyLoadInstance = new LazyLoad()

  MicroModal.init({
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    disableScroll: true,
    disableFocus: true,
    onShow: (modal) => {
      // Analytics
      Analytics.eventGa('Modal', modal.id, '', null, true);
      Analytics.eventAmplitudeAdvanced('Modal', null, {'type': modal.id});
    }
  })

  if (window.location.href.indexOf("checkout") < 0) {
    const myElement = document.querySelector("header")
    const headroom  = new Headroom(myElement, {
      offset: 100,
      tolerance : 10
    })
    headroom.init()
  }

  // Amplitude
  // Analytics.visit()

  // Move content down according to header size
  // TODO: Redo it once browse is resized, especially important for shop, because of filters changing height
  // const headerOffset = document.querySelector('header').offsetHeight;
  // document.querySelector("div[role=main]").style.marginTop = headerOffset + 'px';

})
