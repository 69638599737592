import { Controller } from "stimulus"
let store = require("store")
// import amplitude from 'amplitude-js'

export default class extends Controller {

  connect() {
    // window.amplitude = amplitude
    // amplitude.getInstance().init(document.body.dataset.amp)

    this.checkReferral()
  }

  checkReferral() {
    let url = new URL(window.location.href)
    let ref_value = url.searchParams.get('ref')

    if (ref_value) {
      store.set('referral_code', ref_value)
      store.set('referral_date', new Date().toISOString().slice(0, 10))
    }
  }

}
