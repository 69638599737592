import { Controller } from "stimulus"
let store = require("store")

export default class extends Controller {

  static values = {
    name: String
  }

  static targets = [ "container" ]

  connect() {
    const discarded = store.get(this.nameValue+"-discarded")
    if (discarded === undefined || discarded === null || discarded === "") {
      this.show()
    }
  }

  disconnect() {

  }

  toggle() {
    this.containerTarget.classList.toggle("max-h-16")
    this.containerTarget.classList.toggle("max-h-screen")
  }

  show() {
    this.containerTarget.classList.remove("hidden")
    this.containerTarget.setAttribute("aria-hidden", "false")
  }

  hide() {
    this.containerTarget.classList.add("hidden")
    this.containerTarget.setAttribute("aria-hidden", "true")
    store.set(this.nameValue+"-discarded", "true")
  }

  discard() {
    this.hide()
  }

}
