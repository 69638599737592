import { Controller } from "stimulus"
import { install, uninstall } from "@github/hotkey"

export default class extends Controller {
  static targets = [ "platformModifier" ]

  initialize() {
    // if (this.hasPlatformModifierTarget && isMac) this.platformModifier
  }

  connect() {
    if (this.disabled) return
    install(this.element)
  }

  disconnect() {
    uninstall(this.element)
  }

  get disabled() {
    return document.body.hasAttribute("data-hotkeys-disabled")
  }

}
