import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["container", "item"]

  initialize() {

  }

  connect() {
    this.build()
  }

  disconnect() {

  }

  build() {
    // Adapted from https://fjolt.com/article/css-grid-masonry

    if (this.hasItemTarget) {
      // Programmatically get the column width
      let parentWidth = this.containerTarget.getBoundingClientRect().width
      let itemWidth = this.itemTargets[0].getBoundingClientRect().width
      let columnWidth = Math.round((1 / (itemWidth / parentWidth)))

      let trackHeights = {}
      this.containerTarget.style.height = 'auto'
      this.itemTargets.forEach((item, index) => {
        let thisColumn = index % columnWidth
        if(typeof trackHeights[thisColumn] == "undefined") {
          trackHeights[thisColumn] = 0;
        }
        trackHeights[thisColumn] += item.getBoundingClientRect().height + parseFloat(getComputedStyle(item).marginBottom)
        // If the item has an item above it, then move it to fill the gap
        if(index - columnWidth >= 0) {
          item.style.top = '0px'
          let getItemAbove = this.itemTargets[index - columnWidth]
          let previousBottom = getItemAbove.getBoundingClientRect().bottom
          let currentTop = item.getBoundingClientRect().top - parseFloat(getComputedStyle(item).marginBottom)
          item.style.top = `-${currentTop - previousBottom}px`
        }
      })
      // TODO: Collapsing overlaps in items
      let max = Math.max(...Object.values(trackHeights))
      this.containerTarget.style.height = `${max}px`
    }
  }

}
