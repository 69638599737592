import { Controller } from "stimulus"
let store = require("store")

export default class extends Controller {
  static values = {
    countryCode: String,
    isBilling: Boolean
  }

  static targets = [ 'state', 'country' ]

  initialize() {

  }

  connect() {
    this.refreshStateOnConnect()
  }

  disconnect() {

  }

  // Country

  changeCountry() {
    this.countryCodeValue = this.countryTarget.value
    store.set('country', this.countryCodeValue)
    this.refreshState()
  }



  // State

  refreshState() {
    if (this.countryTarget.value.toLowerCase() == 'us') {
      this.stateTarget.classList.remove('hidden')
      this.stateTarget.querySelector('select').disabled = false
    } else {
      this.stateTarget.classList.add('hidden')
      this.stateTarget.querySelector('select').disabled = true
    }
  }

  refreshStateOnConnect() {
    if (this.countryTarget.value.toLowerCase() == 'us') {
      if (this.isBillingValue) {
        this.stateTarget.classList.remove('hidden')
        this.stateTarget.querySelector('select').disabled = true
      }
      if (!this.isBillingValue) {
        this.stateTarget.classList.remove('hidden')
        this.stateTarget.querySelector('select').disabled = false
      }
    } else {
      this.stateTarget.classList.add('hidden')
      this.stateTarget.querySelector('select').disabled = true
    }
  }

}
