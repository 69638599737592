import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String,
    slug: String,
    type: String,
    title: String,
    titleLink: String
  }

  static targets = [ "container", "items" ]

  connect() {
    this.loadItems()
  }

  disconnect() {

  }

  loadItems() {
    const data = {slug: this.slugValue, type: this.typeValue, title: this.titleValue, title_link: this.titleLinkValue}
    const token = document.querySelector("[name='csrf-token']").content

    fetch(this.urlValue, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((r) => r.text())
    .then((html) => {
      const fragment = document
        .createRange()
        .createContextualFragment(html);
      if (html.length > 0) {
        this.itemsTarget.innerHTML = html
        document.lazyLoadInstance.update();
      } else {
        this.containerTarget.classList.add("hidden")
      }
    })
    .catch((error) => {
      window.application.notify(error)
    })
  }

}
