import { Controller } from "stimulus"
import ahoy from "ahoy.js"

export default class extends Controller {
  static values = {
    source: String
  }

  connect() {
  }

  disconnect() {
  }

  track(event) {
    try {
      ahoy.track("clicked product", {"source": this.sourceValue, "product": event.params.product})
    } catch(e) { window.application.notify(e) }
  }
}
