import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "video", "mutedIcon", "unmutedIcon" ]

  initialize() {

  }

  connect() {
    let iframe = document.querySelector('iframe')
    this.muted = true
    try {
      this.vimeoPlayer = new Vimeo.Player(iframe)
    } catch(e) { }
  }

  disconnect() {
  }

  toggleAudio() {
    if (this.vimeoPlayer !== undefined) {
      this.toggleAudioVimeo()
    } else {
      this.toggleAudioFile()
    }
  }

  toggleAudioVimeo() {
    if (this.muted) {
      this.vimeoPlayer.setVolume(1)
      this.muted = false
    } else {
      this.vimeoPlayer.setVolume(0)
      this.muted = true
    }

    if (this.hasMutedIconTarget && this.hasUnmutedIconTarget) {

      this.mutedIconTarget.classList.add('hidden')
      this.unmutedIconTarget.classList.add('hidden')

      if (this.muted) {
        this.mutedIconTarget.classList.remove('hidden')
      } else {
        this.unmutedIconTarget.classList.remove('hidden')
      }
    }
  }

  toggleAudioFile() {
    if (this.hasVideoTarget) {
      this.videoTarget.muted = !this.videoTarget.muted
    }

    if (this.hasMutedIconTarget && this.hasUnmutedIconTarget) {

      this.mutedIconTarget.classList.add('hidden')
      this.unmutedIconTarget.classList.add('hidden')

      if (this.videoTarget.muted) {
        this.mutedIconTarget.classList.remove('hidden')
      } else {
        this.unmutedIconTarget.classList.remove('hidden')
      }
    }
  }

}
