import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {

  connect() {
    this.sliders = []
    this.initializeSliders()
  }

  disconnect() {
    this.destroySliders()
    this.sliders = []
  }

  initializeSliders() {
    const carouselSliders = this.element.querySelectorAll('[data-slider-simple-carousel]')
    carouselSliders.forEach(slider => {
      let sliderPrev = this.element.querySelector('[data-slider-simple-carousel-prev]')
      let sliderNext = this.element.querySelector('[data-slider-simple-carousel-next]')
      let carouselSlider = tns({
        container: slider,
        prevButton: sliderPrev,
        nextButton: sliderNext,
        items: 1,
        slideBy: 1,
        mouseDrag: true,
        edgePadding: 0,
        gutter: 0,
        autoplay: false,
        lazyload: true,
        nav: false,
        preventScrollOnTouch: "auto",
        swipeAngle: 30,
      })
      this.sliders.push(carouselSlider)
    })
  }

  destroySliders() {
    this.sliders.forEach(slider => {
      slider.destroy()
    })
  }

}
