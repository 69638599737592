import { Controller } from "stimulus"
// import tippy from 'tippy.js'
// import 'tippy.js/dist/tippy.css'

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    this.token = document.querySelector("[name='csrf-token']").content
  }

  disconnect() {

  }

  toggle() {
    if (this.hasContentTarget) {
      this.contentTarget.hidden = !this.contentTarget.hidden
    }
  }

  hide() {
    if (this.hasContentTarget) {
      this.contentTarget.hidden = true
    }
  }

  changeCurrency(event) {
    event.preventDefault()
    this.hide()

    // TODO: This function is causing some issues related to paypal

    let currencyCode = ""
    if (event.target.dataset.currency) {
      currencyCode = event.target.dataset.currency
    }
    if (event.target.value) {
      currencyCode = event.target.value
    }

    fetch('/session/currency', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({code: currencyCode}),
    })
    .then((data) => {
      window.location.reload(true)
    })
    .catch(error => {
      this.application.notify(error)
    })


  }

}
