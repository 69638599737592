import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
let store =  require("store")

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export default class extends Controller {
  static values = {
    cart_uid: String,
    currency: String,
    country: String,
    price: Boolean,
    ringsize: String,
    ringsizeLocal: String,
    language: String,
    freeShippingUrl: String,
    // mothersDayLimit: Number,
    // abtest: String
  }
  static targets = [ "toggleable", "overlay", "slideIn", "emptyState", "items", "crossSell", "crossSellPost", "count", "checkout", "ringsizeSelector", "ringsizeSelectorMobile", "ringsizeButton", "ringsizeButtonMobile", "addToCartButton", "freeShipping" ]
  // "mothersDayNotEligible", "mothersDayEligible", "mothersDayProgressBar", "mothersDayMoreAmount"

  initialize() {
  }

  connect() {
    // Check if localStorage.cart_items is broken
    if (store.get('cart_items') === undefined || store.get('cart_items') == '' || store.get('cart_items') == [] || store.get('cart_items') == '[]') {
      store.remove('cart_items')
      this.itemsValue = []
    } else {
      try {
        this.itemsValue = JSON.parse(store.get('cart_items'))
      } catch (error) {
        window.application.notify(error)
        store.remove('cart_items')
        this.itemsValue = []
      }
    }
    this.token = document.querySelector("[name='csrf-token']").content
    this.initializeItems()
    // this.initialize2024MothersDay()
    // this.initializeFreeShipping(null)
    this.updateCount()

    if (document.querySelector('[data-slider-cart]') === 0) {
      const cartSlider = tns({
        container: document.querySelector('[data-slider-cart]'),
        items: 2,
        slideBy: 1,
        mouseDrag: true,
        edgePadding: 0,
        gutter: 0,
        autoplay: true,
        autoplayButton: false,
        autoplayButtonOutput: false,
        nav: false,
        controls: false,
        preventScrollOnTouch: "auto",
        swipeAngle: 30,
      })
    }

    if (this.hasRingsizeButtonMobileTarget) {

      this.ringsizeSelectorMobiles = []
      this.ringsizeButtonMobileTargets.forEach ( target => {
        let selector = tippy(target, {
            content: this.ringsizeSelectorMobileTarget.innerHTML,
            maxWidth: 460,
            interactive: true,
            allowHTML: true,
            trigger: 'click',
            theme: 'light'
        });
        this.ringsizeSelectorMobiles.push(selector)
      })
    }
  }

  toggle(event=null) {
    if (event) event.preventDefault()

    // Body
    document.body.classList.toggle('overflow-hidden')

    // Container
    this.toggleableTarget.classList.toggle('invisible')
    this.toggleableTarget.classList.toggle('pointer-events-none')

    // Overlay
    this.overlayTarget.classList.toggle('opacity-60')
    this.overlayTarget.classList.toggle('opacity-0')
    this.overlayTarget.classList.toggle('delay-200')

    // Contents
    this.slideInTarget.classList.toggle('right-0')
    this.slideInTarget.classList.toggle('-right-76')

    // Close chat
    // try {
    //   window.Beacon('close')
    // } catch (error) {
    //   window.application.notify(error)
    // }
  }

  close(event=null) {
    // if (event) event.preventDefault()

    // Body
    document.body.classList.remove('overflow-hidden')

    // Container
    this.toggleableTarget.classList.add('invisible')
    this.toggleableTarget.classList.add('pointer-events-none')

    // Overlay
    this.overlayTarget.classList.remove('opacity-60')
    this.overlayTarget.classList.add('opacity-0')
    this.overlayTarget.classList.add('delay-200')

    // Contents
    this.slideInTarget.classList.remove('right-0')
    this.slideInTarget.classList.add('-right-76')

    // If user is in checkout page, refresh checkout cart summary and payment info
    if (window.location.href.indexOf("checkout") != -1) {
      // window.location.reload()
      this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'checkout').refresh()
      this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'payment').refresh()
    }
  }

  open(event=null) {
    // if (event) event.preventDefault()

    // Body
    document.body.classList.add('overflow-hidden')

    // Container
    this.toggleableTarget.classList.remove('invisible')
    this.toggleableTarget.classList.remove('pointer-events-none')

    // Overlay
    this.overlayTarget.classList.add('opacity-60')
    this.overlayTarget.classList.remove('opacity-0')
    this.overlayTarget.classList.remove('delay-200')

    // Contents
    this.slideInTarget.classList.add('right-0')
    this.slideInTarget.classList.remove('-right-76')
  }

  initializeItems() {
    // Fetch new html over the wire for cart items
    this.htmlValue = ""
    let itemsProcessed = 0

    this.itemsValue.forEach( item => {
      // let url = new URL(item.url)
      let url = this.formatUrl(item.url)
      item.language = this.languageValue
      url.search = new URLSearchParams(item).toString();

      fetch(url)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);
          this.htmlValue = this.htmlValue + html
          itemsProcessed++
          if (itemsProcessed === this.itemsValue.length) {
            this.itemsTarget.innerHTML = this.htmlValue
          }
        });
    })

  }

  // 2024 Mother's day campaign
  // initialize2024MothersDay() {
  //   if (!this.hasMothersDayProgressBarTarget) { return }
  //   this.cartTotalEur = 0
  //   this.itemsValue.forEach( item => {
  //     this.cartTotalEur += parseFloat(item.europrice)
  //   })
  //   let percent = Math.min(Math.round(this.cartTotalEur * 100 / this.mothersDayLimitValue), 100)
  //   this.mothersDayProgressBarTarget.style.width = `${percent}%`

  //   // Show correct number
  //   let missingAmount = Math.max(this.mothersDayLimitValue - this.cartTotalEur, 0)
  //   this.mothersDayMoreAmountTarget.innerHTML = missingAmount

  //   if (missingAmount <= 0) {
  //     // Show/hide progress text and button
  //     this.mothersDayEligibleTarget.classList.remove('hidden')
  //     this.mothersDayEligibleTarget.classList.remove('pointer-events-none')
  //     this.mothersDayNotEligibleTarget.classList.add('hidden')
  //     this.mothersDayNotEligibleTarget.classList.add('pointer-events-none')
  //   } else {
  //     // Show/hide progress text and button
  //     this.mothersDayEligibleTarget.classList.add('hidden')
  //     this.mothersDayEligibleTarget.classList.add('pointer-events-none')
  //     this.mothersDayNotEligibleTarget.classList.remove('hidden')
  //     this.mothersDayNotEligibleTarget.classList.remove('pointer-events-none')

  //     // Remove flower from cart if it is in cart
  //     let indexFlower = this.itemsValue.findIndex(v => v.slug === 'mothers-day-2024-flowers')
  //     if (indexFlower >= 0) {
  //       let cart_item_uid = this.itemsValue[indexFlower].cart_item_uid
  //       let itemsArray = JSON.parse(store.get('cart_items'))
  //       this.itemsValue = itemsArray.filter(obj => obj.slug !== 'mothers-day-2024-flowers')
    
  //       // Store items in localStorage
  //       store.set('cart_items', JSON.stringify(this.itemsValue));
    
  //       // Remove item from target html container
  //       this.itemsTarget.querySelector(`div[data-uid='${cart_item_uid}']`).remove()
   
  //       // If user is in checkout page, refresh checkout cart summary and payment info
  //       if (window.location.href.indexOf("checkout") != -1) {
  //         this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'checkout').refresh()
  //         this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'payment').refresh()
  //       }
    
  //       // Update count in cart icon in header
  //       this.updateCount() 
  //     }
  //   }
  // }

  // Show free shipping information inside cart

  initializeFreeShipping(country_code = null) {

    let data = { "country_code": country_code }

    fetch(this.freeShippingUrlValue, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((r) => { if (!r.ok) { this.application.notify(r.statusText) } return r })
    .then((r) => r.text())
    .then((html) => {
      const fragment = document
        .createRange()
        .createContextualFragment(html);
      this.freeShippingTarget.innerHTML = html
    })
    .catch((error) => {
      Bugsnag.notify(error)
    })

  }


  // Update count in header

  updateCount() {
    if (this.itemsValue.length > 0) {

      // Header
      if (this.hasCountTarget) {
        this.countTarget.innerHTML = this.itemsValue.length
      }

      // Checkout button
      if (this.hasCheckoutTarget) {
        this.checkoutTarget.style.display = "block"
      }

      // Empty state
      if (this.hasEmptyStateTarget) {
        this.emptyStateTarget.classList.add("hidden")
      }
      // Cross sell
      if (this.hasCrossSellTarget) {
        let crossSellProduct = this.crossSellTarget.dataset.crossSellProduct
        if (this.itemsValue.filter(function(e) { return e['slug'] === crossSellProduct }).length == 0) {
          this.crossSellTarget.classList.remove("hidden")
          if (this.hasCrossSellPostTarget) { this.crossSellPostTarget.classList.add("hidden") }
        } else {
          this.crossSellTarget.classList.add("hidden")
          if (this.hasCrossSellPostTarget) { this.crossSellPostTarget.classList.remove("hidden") }
        }
      }

    } else {

      // Header
      if (this.hasCountTarget) {
        this.countTarget.innerHTML = ""
      }

      // Checkout button
      if (this.hasCheckoutTarget) {
        this.checkoutTarget.style.display = "none"
      }

      // Content & Empty state
      if (this.hasItemsTarget) {
        this.itemsTarget.innerHTML = ""
      }
      if (this.hasEmptyStateTarget) {
        this.emptyStateTarget.classList.remove("hidden")
      }
      // Cross sell
      if (this.hasCrossSellTarget) {
        this.crossSellTarget.classList.add("hidden")
      }
    }
  }

  // Show Size Guide
  showSizeguide(event) {
    MicroModal.show('modal-sizeguide', {
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableScroll: true,
      disableFocus: true,
      onShow: (modal) => {
        // Analytics
        Analytics.eventGa('Modal', modal.id, '', null, true);
        Analytics.eventAmplitudeAdvanced('Modal', null, {'type': modal.id});
      }
    })
  }

  showSizeguideNew(event) {
    MicroModal.show('modal-sizeguide-new',{
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableScroll: true,
      disableFocus: true,
      onShow: (modal) => {
        // Analytics
        Analytics.eventGa('Modal', modal.id, '', null, true);
        Analytics.eventAmplitudeAdvanced('Modal', null, {'type': modal.id});
      }
    })
  }


  // Choose Ring Size
  chooseRingSize(event) {
    let target = event.target
    this.ringsizeValue = target.dataset.ringsize
    this.ringsizeLocalValue = target.dataset.ringsizeLocal
    let highlightClass = target.dataset.highlight

    let siblings = event.target.parentNode.querySelectorAll(".ringsize")

    // Highlight selected ring size
    this.ringsizeSelectorTargets.forEach( selector => {
      selector.querySelectorAll('.ringsize').forEach( sibling => {
        // Reset all
        sibling.classList.remove(highlightClass)
        sibling.classList.add("border-gray-200")
        // Higlight selected
        if (sibling.dataset.ringsize == this.ringsizeValue) {
          sibling.classList.remove("border-gray-200")
          sibling.classList.add(highlightClass)
        }
      })
    })

    // Mobile: Close tippy ring size selector
    if (this.hasRingsizeButtonMobileTarget) {
      this.ringsizeSelectorMobiles.forEach (selector => {
        selector.hide()
        // Mobile: Update contents of tippy ring size selector
        selector.setContent(this.ringsizeSelectorMobileTarget.innerHTML)

        // Mobile: Show selected size on the button and adjust size
        this.ringsizeButtonMobileTargets.forEach(button => {
          let buttonCopy = button.querySelector('span[data-before-select]')
          buttonCopy.innerHTML = buttonCopy.getAttribute('data-after-select')
          button.querySelector('span[data-selected-size]').innerHTML = ': ' + this.ringsizeLocalValue
          button.classList.remove("flex-grow")
        })
      })
    }

    // Hide Choose Ringsize button
    this.ringsizeButtonTarget.classList.add("hidden")

    // Enable Add To Cart button
    this.addToCartButtonTargets.forEach( button => {
      button.classList.remove("hidden")
    })

  }



  // Add To Cart

  add(event) {
    // Do not add if there are more than 40 items in the cart
    if (this.itemsValue.length > 40) {
      MicroModal.show('modal-too-many-items')
      return
    }

    // Clean up for Mothers Day 2024
    let tmpRingsizeValue = this.ringsizeValue
    if (event.target.dataset.slug == "mothers-day-2024-flowers") {
      this.ringsizeValue = ""
    }

    // Get values
    const url = event.target.dataset.url
    const sku = event.target.dataset.sku
    const slug = event.target.dataset.slug
    const variation = event.target.dataset.variation
    const ringsize = this.ringsizeValue
    const cart_item_uid = Math.random().toString(36).substring(2) + "-" + Date.now()

    // Post Clean up for Mothers Day 2024
    this.ringsizeValue = tmpRingsizeValue

    // Values for analytics/reporting
    const name = event.target.dataset.name
    const titleEn = event.target.dataset.titleEn
    const titleFr = event.target.dataset.titleFr
    const europrice = event.target.dataset.europrice
    const skuvariation = sku + (variation === "" ? "" : "-" + variation.toUpperCase())
    const image = event.target.dataset.image
    const productUrl = event.target.dataset.producturl
    const fullslug = [slug, variation].filter(Boolean).join('-')

    // Assign values to object
    let item = {sku: sku, slug: slug, variation: variation, ringsize: ringsize, url: url, europrice: europrice, cart_item_uid: cart_item_uid, product_url: productUrl, image: image, title_en: titleEn, title_fr: titleFr}

    try {
      // Add to items array
      // AB TEST: Cart Multiple Tabs
      // let itemsArray
      // if (this.abtestValue == "cart_tabs") {
      //   // Multiple Tabs
      //   itemsArray = JSON.parse(store.get('cart_items'))
      // } else {
      //   // Original
      //   itemsArray = this.itemsValue
      // }

      // Multiple Tabs
      let itemsArray = JSON.parse(store.get('cart_items'))


      itemsArray.push(item)
      this.itemsValue = itemsArray
    } catch (error) {
      store.remove('cart_items')
      this.itemsValue = []
      this.itemsValue.push(item)
    }

    // Store array in localStorage
    store.set('cart_items', JSON.stringify(this.itemsValue));

    // Update count in cart icon in header
    this.updateCount()

    // Refresh cross sell
    // this.initialize2024MothersDay()

    // Show cart
    const loader = "<div class='loader w-full h-24 flex justify-center items-center'><svg class='animate-spin h-5 w-5 text-black' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'><circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'></circle><path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path></svg><div>"

    this.itemsTarget.insertAdjacentHTML('beforeend', loader)
    this.open()

    // Fetch and add item to target html container
    // let urlObject = new URL(url)
    let urlObject = this.formatUrl(url)
    item.adding_to_cart = "true"
    urlObject.search = new URLSearchParams(item).toString();
    fetch(urlObject)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);
        this.itemsTarget.querySelector('.loader').remove()
        this.itemsTarget.insertAdjacentHTML('beforeend', html)
        item.adding_to_cart = ""
      });

    // Analytics
    Analytics.eventGa('Checkout Flow', 'Add To Cart', sku, europrice)
    Analytics.eventAmplitudeAdvanced('Checkout Flow', 'Add To Cart', {'sku': sku})
    FacebookConversion.addToCart(skuvariation, parseInt(europrice), cart_item_uid)
    Analytics.addToCart({
      "currency": "EUR",
      "value": parseFloat(europrice),
      "items": [{
        // "item_id": sku,
        "item_name": fullslug,
        "price": parseFloat(europrice)
      }]
    })
    Klaviyo.addedToCart({
      "price": europrice,
      "name": name,
      "title_en": titleEn,
      "title_fr": titleFr,
      "slug": slug,
      "skuvariation": skuvariation,
      "image": image,
      "url": productUrl,
      "price": europrice
  }, this.itemsValue)
  }



  // Remove From Cart

  remove(event) {
    const cart_item_uid = event.target.dataset.removeUid

    const sku = event.target.dataset.sku
    const slug = event.target.dataset.slug

    // Remove item from array
    // AB TEST: Cart Multiple Tabs
    // if (this.abtestValue == "cart_tabs") {
    //   // Multiple Tabs
    //   let itemsArray = JSON.parse(store.get('cart_items'))
    //   this.itemsValue = itemsArray.filter(obj => obj.cart_item_uid !== cart_item_uid)
    // } else {
    //   // Original
    //   this.itemsValue = this.itemsValue.filter(obj => obj.cart_item_uid !== cart_item_uid)
    // }

    // Multiple Tabs
    let itemsArray = JSON.parse(store.get('cart_items'))
    this.itemsValue = itemsArray.filter(obj => obj.cart_item_uid !== cart_item_uid)



    // Store items in localStorage
    store.set('cart_items', JSON.stringify(this.itemsValue));


    // Remove item from target html container
    this.itemsTarget.querySelector(`div[data-uid='${cart_item_uid}']`).remove()
    // console.log(this.itemsTarget)

    // If user is in checkout page, refresh checkout cart summary and payment info
    if (window.location.href.indexOf("checkout") != -1) {
      this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'checkout').refresh()
      this.application.getControllerForElementAndIdentifier(document.querySelector('#checkout'), 'payment').refresh()
    }

    // Update count in cart icon in header
    this.updateCount()

    // Refresh cross sell
    // this.initialize2024MothersDay()

    // Analytics
    Analytics.eventGa('Checkout Flow', 'Remove From Cart', sku)
    Analytics.eventAmplitudeAdvanced('Checkout Flow', 'Remove From Cart', {'sku': sku})

    Analytics.removeFromCart({
      "id": sku,
      "name": slug,
    })
  }



  // Change Country

  changeCountry(event) {
    // this.initializeFreeShipping(event.target.value)
  }


  // Format URL For Items
  formatUrl(oldUrl) {
    oldUrl = this.formatValidUrl(oldUrl)
    let newUrl = new URL(oldUrl)
    newUrl.host = window.location.host
    newUrl.protocol = window.location.protocol
    return newUrl
  }

  formatValidUrl(string) {
    try {
      let url = new URL(string)
    } catch (_) {
      let formattedUrl = new URL(string, window.location.origin)
      string = formattedUrl.href
    }

    return string
  }

  offset(el) {
    let rect = el.getBoundingClientRect()
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }
}
