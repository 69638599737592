import { Controller } from "stimulus"
let store = require("store")

export default class extends Controller {

  static values = {
    name: String
  }

  connect() {
    this.nameValue = 'exit-intent'
    const discarded = store.get(this.nameValue+"-discarded")
    if (discarded === undefined || discarded === null || discarded === "") {
      this.enable()
    }
  }

  disconnect() {

  }

  enable() {
    document.addEventListener('mouseout', this.mouseEvent)
  }

  mouseEvent(e) {
    if (!e.toElement && !e.relatedTarget) {
      document.removeEventListener('mouseout', this.mouseEvent)
      MicroModal.show('modal-subscribe')
    }
  }

  toggle() {
    this.containerTarget.classList.toggle("max-h-16")
    this.containerTarget.classList.toggle("max-h-screen")
  }

  show() {
    this.containerTarget.classList.remove("hidden")
    this.containerTarget.setAttribute("aria-hidden", "false")
  }

  hide() {
    this.containerTarget.classList.add("hidden")
    this.containerTarget.setAttribute("aria-hidden", "true")
    store.set(this.nameValue+"-discarded", "true")
  }

  discard() {
    this.hide()
  }

}
