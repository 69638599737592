// Analytics with dataLayer and Google Tag Manager
// Compatible for both UA and GA4

class Analytics {
  constructor() { }

  // static event(category, action, label, value, interaction) {
  //   // Google Analytics
  //   Analytics.eventGa(category, action, label, value, interaction)
  //
  //   // Amplitude
  //   Analytics.eventAmplitude(category, action, label)
  // }

  static eventGa(category, action, label, value, interaction) {
    return
    // console.log("GA ->", category, action, label, value, interaction)
    // return
    // ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject])

    if (typeof interaction === 'undefined') { interaction = false }

    try {
      if (typeof value === 'undefined') {
        gtag('event', action, {
          'event_category' : category,
          'event_label' : label
        })
      } else {
        gtag('event', action, {
          'event_category' : category,
          'event_label' : label,
          'value': parseFloat(value),
          'non_interaction': interaction
        })
      }
    } catch(e) { window.application.notify(e) }
  }

  static eventAmplitude(category, action, label) {
    return
    // console.log("Amplitude ->", category, action, label)
    // return

    try {
      var eventlabel = category
      if (action) { eventlabel += ", " + action }
      if (label) { eventlabel += ", " + label }
      window.amplitude.getInstance().logEvent(eventlabel)
    } catch(e) { window.application.notify(e) }
  }

  static eventAmplitudeAdvanced(category, action, properties) {
    return
    // console.log("Amplitude Advanced ->", category, action, properties)
    // return

    try {
      var eventlabel = category
      if (action) { eventlabel += ", " + action }
      window.amplitude.getInstance().logEvent(eventlabel, properties)
    } catch(e) { window.application.notify(e) }
  }

  static visit() {
    return
    try {
      window.amplitude.getInstance().setUserProperties({'user_agent': window.navigator.userAgent})
      window.amplitude.getInstance().logEvent(window.location.pathname)
    } catch(e) { window.application.notify(e) }
  }

  // static timing(category, variable, value) {
  //   try {
  //     gtag('event', 'timing_complete', {
  //       'name' : variable,
  //       'value' : parseInt(value),
  //       'event_category' : 'category'
  //     })
  //   } catch(e) { window.application.notify(e) }
  // }

  static productInfo(options) {
    // console.log("GA view_item ->", options)
    // return

    try {
      gtag('event', 'view_item', options)
    } catch(e) { window.application.notify(e) }
  }

  static addToCart(options) {
    // console.log("GA add_to_cart ->", options)
    // return

    try {
      gtag('event', 'add_to_cart', options)
    } catch(e) { window.application.notify(e) }
  }

  static removeFromCart(options) {
    // console.log("GA remove_from_cart ->", options)
    // return

    try {
      gtag('event', 'remove_from_cart', {
        "items": [options]
      })
    } catch(e) { window.application.notify(e) }
  }

  static checkout(options) {
    options.items = Analytics.mergeNonUniqueItems(options.items)
    // console.log("GA begin_checkout ->", options)
    // return

    try {
      gtag('event', 'begin_checkout', options)
    } catch(e) { window.application.notify(e) }
  }

  // static checkoutChangeCountry(options) {
  //   // console.log("GA checkoutChangeCountry ->", options)
  //   // return

  //   try {
  //     gtag('event', 'set_checkout_option', {
  //       "checkout_option": "country",
  //       "value": options
  //     })
  //   } catch(e) { window.application.notify(e) }
  // }

  // static checkoutPayment(options) {
  //   // Not used
  //   try {
  //     gtag('event', 'checkout_progress', {
  //       "items": [options],
  //       "coupon": ""
  //     })
  //   } catch(e) { window.application.notify(e) }
  // }

  static purchase(options, nb_data) {
    options.items = Analytics.mergeNonUniqueItems(options.items, 'item_name')
    nb_data.lineItems = Analytics.mergeNonUniqueItems(nb_data.lineItems, 'productId')
    nb_data.customerId = Analytics.SHA1(nb_data.customerId.toLowerCase())
    // console.log("GA purchase ->", options)
    // console.log("GA purchase NB ->", nb_data)
    // return

    try {
      gtag('event', 'purchase', options)
    } catch(e) { window.application.notify(e) }

    // try {

    //   const func = () => { window.Northbeam.firePurchaseEvent(nb_data) }
    //   if(window.Northbeam){
    //     // if Northbeam is already loaded, just call the firePurchaseEvent function
    //     func()
    //   } else {
    //     // If Northbeam is not loaded, save your function
    //     // in window.onNorthbeamLoad, so the pixel will call
    //     // it as soon as window.Northbeam is defined.
    //     window.onNorthbeamLoad = func
    //   }

    // } catch(e) { window.application.notify(e) }
  }

  static mergeNonUniqueItems(items, uniqueKey) {
    // Group same items in options.items by adding their quantities and prices
    let uniqueItems = []
    let processedKeys = []

    items.forEach((item) => {
      let name = item[uniqueKey]
      if (processedKeys.includes(name)) {
        let index = uniqueItems.findIndex(v => v[uniqueKey] === name)
        uniqueItems[index].quantity += item.quantity
        uniqueItems[index].price += item.price
      } else {
        processedKeys.push(item[uniqueKey])
        uniqueItems.push(item)
      }
    })

    return uniqueItems
  }

  static SHA1(msg) {
    /**
    * Secure Hash Algorithm (SHA1)
    * http://www.webtoolkit.info/
    **/
    function rotate_left(n,s) {var t4=( n<<s ) | (n>>>(32-s));return t4;}
    function lsb_hex(val) {var str='';var i;var vh;var vl;for( i=0; i<=6; i+=2 ) {vh = (val>>>(i*4+4))&0x0f;vl = (val>>>(i*4))&0x0f;str += vh.toString(16) + vl.toString(16);}return str;}
    function cvt_hex(val) {var str='';var i;var v;for( i=7; i>=0; i-- ) {v = (val>>>(i*4))&0x0f;str += v.toString(16);}return str;};
    function Utf8Encode(string) {
      string = string.replace(/\r\n/g,'\n');
      var utftext = '';
      for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
      utftext += String.fromCharCode(c);
      }
      else if((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
      }
      }
      return utftext;
      };
      var blockstart;
      var i, j;
      var W = new Array(80);
      var H0 = 0x67452301;
      var H1 = 0xEFCDAB89;
      var H2 = 0x98BADCFE;
      var H3 = 0x10325476;
      var H4 = 0xC3D2E1F0;
      var A, B, C, D, E;
      var temp;
      msg = Utf8Encode(msg);
      var msg_len = msg.length;
      var word_array = new Array();
      for( i=0; i<msg_len-3; i+=4 ) {
      j = msg.charCodeAt(i)<<24 | msg.charCodeAt(i+1)<<16 |
      msg.charCodeAt(i+2)<<8 | msg.charCodeAt(i+3);
      word_array.push( j );
      }
      switch( msg_len % 4 ) {
      case 0:
      i = 0x080000000;
      break;
      case 1:
      i = msg.charCodeAt(msg_len-1)<<24 | 0x0800000;
      break;
      case 2:
      i = msg.charCodeAt(msg_len-2)<<24 | msg.charCodeAt(msg_len-1)<<16 | 0x08000;
      break;
      case 3:
      i = msg.charCodeAt(msg_len-3)<<24 | msg.charCodeAt(msg_len-2)<<16 | msg.charCodeAt(msg_len-1)<<8 | 0x80;
      break;
      }
      word_array.push( i );
      while( (word_array.length % 16) != 14 ) word_array.push( 0 );
      word_array.push( msg_len>>>29 );
      word_array.push( (msg_len<<3)&0x0ffffffff );
      for ( blockstart=0; blockstart<word_array.length; blockstart+=16 ) {
        for( i=0; i<16; i++ ) W[i] = word_array[blockstart+i];
        for( i=16; i<=79; i++ ) W[i] = rotate_left(W[i-3] ^ W[i-8] ^ W[i-14] ^ W[i-16], 1);
        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;
        for( i= 0; i<=19; i++ ) {
          temp = (rotate_left(A,5) + ((B&C) | (~B&D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotate_left(B,30);
          B = A;
          A = temp;
        }
        for( i=20; i<=39; i++ ) {
          temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotate_left(B,30);
          B = A;
          A = temp;
        }
        for( i=40; i<=59; i++ ) {
          temp = (rotate_left(A,5) + ((B&C) | (B&D) | (C&D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotate_left(B,30);
          B = A;
          A = temp;
          }
        for( i=60; i<=79; i++ ) {
          temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
          E = D;
          D = C;
          C = rotate_left(B,30);
          B = A;
          A = temp;
        }
        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
      }

    var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
    return temp.toLowerCase();
  }
}

export default Analytics
window.Analytics = Analytics
