import { Controller } from "stimulus"
import Swiper from 'swiper'

export default class extends Controller {

  static targets = [ "container", "controls" ]

  connect() {
    this.swiperInstance = null
    this.slideAmount = 0
    this.initializeSwiperSlider()
    this.checkNavButtons()
  }

  disconnect() {
    this.swiperInstance.destroy()
    this.swiperInstance = null
  }

  initializeSwiperSlider() {
    if (!this.hasContainerTarget) { return }
    this.slideAmount = this.containerTarget.querySelectorAll('.swiper-slide').length
    this.swiperInstance = new Swiper(this.containerTarget, {
      loop: true,
      enabled: true,
      spaceBetween: 1,
      centerInsufficientSlides: true,
      slidesPerView: Math.min(this.slideAmount, 1.5),
      breakpoints: {
        320: {
          slidesPerView: Math.min(this.slideAmount, 1.5)
        },
        500: {
          slidesPerView: Math.min(this.slideAmount, 2.5)
        },
        768: {
          slidesPerView: Math.min(this.slideAmount, 2.5)
        },
        880: {
          slidesPerView: Math.min(this.slideAmount, 3.5)
        },
        1024: {
          slidesPerView: Math.min(this.slideAmount, 4.5)
        },
        1280: {
          slidesPerView: Math.min(this.slideAmount, 5.5)
        }
      }
    });
  }

  checkNavButtons() {
    if (this.slideAmount < 3) {
      this.controlsTarget.classList.add("hidden")
      this.controlsTarget.setAttribute("aria-hidden", "true")
    }
  }

  next() {
    this.swiperInstance.slideNext()
  }

  prev() {
    this.swiperInstance.slidePrev()
  }

}
