class FacebookConversion {
  constructor() { }

  static viewContent(sku, price, uid) {
    // console.log('Facebook viewContent ->', sku, price)
    // return
    if (document.body.dataset.environment == 'development') { return }

    try {
      fbq('track', 'ViewContent', {
        content_ids: [sku],
        value: price,
        currency: "EUR",
        content_type: "product",
        contents: [{'id': sku, 'quantity': 1, 'item_price': price}]
      }, { eventID: uid });

      // snaptr('track', 'VIEW_CONTENT', {
      //   'currency': 'EUR',
      //   'price': price,
      //   'item_ids': [sku]
      // });

      // pintrk('track', 'pagevisit', {
      //   line_items: [ { product_id: sku } ]
      // })

      // ttq.track('ViewContent',{
      //   content_id: sku,
      //   content_type: 'product',
      //   value: price,
      //   currency: 'EUR'
      // })


    } catch(e) { window.application.notify(e) }
  }

  static addToCart(sku, price, cart_item_uid) {
    // console.log('Facebook addToCart ->', sku, price)
    // return
    if (document.body.dataset.environment == 'development') { return }

    try {
      fbq('track', 'AddToCart', {
        content_ids: [sku],
        value: price,
        currency: "EUR",
        content_type: "product",
        contents: [{'id': sku, 'quantity': 1, 'item_price': price}]
      }, { eventID: cart_item_uid });

      // snaptr('track', 'ADD_CART', {
      //   'currency': 'EUR',
      //   'price': price,
      //   'item_ids': [sku]
      // });

      // pintrk('track', 'addtocart', {
      //   value: price,
      //   order_quantity: 1,
      //   currency: 'EUR',
      //   line_items: [ { product_id: sku } ]
      // })

      // ttq.track('AddToCart',{
      //   content_id: sku,
      //   content_type: 'product',
      //   quantity: 1,
      //   value: price,
      //   currency: 'EUR'
      // })
    } catch(e) { window.application.notify(e) }
  }

  static initiateCheckout(items, value, checkoutid, countryCode) {
    // console.log('Facebook initiateCheckout ->', items, value, checkoutid, countryCode)
    // return
    if (document.body.dataset.environment == 'development') { return }

    try {
      var skus = items.map(function(item) { return item.id });
      fbq('track', 'InitiateCheckout', {
        content_ids: skus,
        value: value,
        currency: "EUR",
        content_type: "product",
        contents: items
      }, { eventID: checkoutid });

      // snaptr('track', 'START_CHECKOUT', {
      //   'currency': "EUR",
      //   'price': value,
      //   'item_ids': skus
      // });

      // var contents = items.map(function(item) { return {
      //   content_id: item.id,
      //   content_type: 'product',
      //   quantity: 1,
      //   price: item.item_price
      // }})

      // ttq.track('InitiateCheckout',{
      //   contents: contents,
      //   content_type: 'product',
      //   value: value,
      //   currency: 'EUR'
      // })
    } catch(e) { window.application.notify(e) }

    // Send extra events
    // try {
    //   let zone = FacebookConversion.countryToZone(countryCode)
    //   if (zone) {
    //     fbq('track', 'InitiateCheckout - '+zone, {
    //       content_ids: skus,
    //       value: value,
    //       currency: "EUR",
    //       content_type: "product",
    //       contents: items
    //     }, { eventID: "zone-"+checkoutid });
    //   }

    // } catch(e) { window.application.notify(e) }

    // try {
    //   fbq('track', 'InitiateCheckout - '+countryCode.toLowerCase(), {
    //     content_ids: skus,
    //     value: value,
    //     currency: "EUR",
    //     content_type: "product",
    //     contents: items
    //   }, { eventID: "country-"+checkoutid });

    // } catch(e) { window.application.notify(e) }

  }

  static addPaymentInfo() {
    // console.log('Facebook addPaymentInfo ->')
    // return
    if (document.body.dataset.environment == 'development') { return }

    try {
      fbq('track', 'AddPaymentInfo');
    } catch(e) { window.application.notify(e) }
  }

  static purchase(items, value, currency, orderuid, countryCode) {
    // console.log('Facebook purchase ->', items, value, currency, orderuid, countryCode)
    // return
    if (document.body.dataset.environment == 'development') { return }

    try {
      var skus = items.map(function(item) { return item.id });
      fbq('track', 'Purchase', {
        content_ids: skus,
        value: value,
        currency: currency,
        content_type: "product",
        contents: items
      }, { eventID: orderuid });


      // snaptr('track', 'PURCHASE', {
      //   'currency': currency,
      //   'price': value,
      //   'item_ids': skus
      // });

      // pintrk('track', 'checkout', {
      //   value: value,
      //   currency: currency
      // })

      // var contents = items.map(function(item) { return {
      //   content_id: item.id,
      //   content_type: 'product',
      //   quantity: 1,
      //   price: item.item_price
      // }})

      // ttq.track('PlaceAnOrder',{
      //   contents: contents,
      //   content_type: 'product',
      //   value: value,
      //   currency: currency
      // })

      // window.application.notify('Log Purchase Event ID', {eventID: orderuid})

    } catch(e) { window.application.notify(e) }

    // Send extra events
    // try {
    //   let zone = FacebookConversion.countryToZone(countryCode)
    //   if (zone) {
    //     fbq('track', 'Purchase - '+zone, {
    //       content_ids: skus,
    //       value: value,
    //       currency: currency,
    //       content_type: "product",
    //       contents: items
    //     }, { eventID: "zone-"+orderuid });
    //   }

    // } catch(e) { window.application.notify(e) }

    // try {
    //   fbq('track', 'Purchase - '+countryCode.toLowerCase(), {
    //     content_ids: skus,
    //     value: value,
    //     currency: currency,
    //     content_type: "product",
    //     contents: items
    //   }, { eventID: "country-"+orderuid });

    // } catch(e) { window.application.notify(e) }

  }

  static countryToZone(country) {
    let europe = ["at", "be", "bg", "ch", "cy", "cz", "de", "dk", "ee", "el", "es", "fi", "fr", "gr", "hr", "hu", "ie", "is", "it", "li", "lt", "lu", "lv", "mc", "mt", "nl", "no", "pl", "pt", "ro", "se", "si", "sk", "uk"]
    let countryCode = country.toLowerCase()
    if (europe.includes(countryCode)) {
      return "europe"
    } else {
      return false
    }
  }
}

export default FacebookConversion
window.FacebookConversion = FacebookConversion
