// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Polyfills
import URLSearchParams from '@ungap/url-search-params'
import 'url-polyfill'
import 'whatwg-fetch'

// forEach Polyfill
if (window.NodeList && !NodeList.prototype.forEach) { NodeList.prototype.forEach = Array.prototype.forEach }

const application = Application.start()
window.application = application
window.definitionsFromContext = definitionsFromContext

// Begin Bugsnag as default error handler

import Bugsnag from '@bugsnag/js'
window.bugsnag = Bugsnag.start('fb428e1dafd461f22f5b978870f4b7e1')
const defaultHandler = application.handleError.bind(application)

import BugsnagPerformance from '@bugsnag/browser-performance'
BugsnagPerformance.start('fb428e1dafd461f22f5b978870f4b7e1')

const bugsnagHandler = (error, details = {}) => {
  defaultHandler(error, details)
  application.notify(error, details)
}
application.handleError = bugsnagHandler

application.notify = (error, details = {}) => {
  // Don't send errors for Facebook Bot. GA, Amplitude, Facebook, Klaviyo is not loaded, so they will generate errors, no need to send them.
  if (window.navigator.userAgent.indexOf('facebookexternalhit/1.1') !== -1 ) { return }

  try {
    if (error instanceof Error) {
      Bugsnag.notify(error, function (event) {
        event.addMetadata('details', details)
      })
    } else {
      Bugsnag.notify(new Error('dont know what this error is'), function (event) {
        event.addMetadata('details', details)
      })
    }
  } catch (error) {

  }
}

application.leaveBreadcrumb = (message) => {
  try {
    Bugsnag.leaveBreadcrumb(message)
  } catch (error) {

  }
}

// End Bugsnag as default error handler

const context = require.context("controllers/application", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
