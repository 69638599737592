

class Klaviyo {
  constructor() { }

  static identify(options) {
    // console.log('Klaviyo identify ->', options)
    // return

    try {
      window._learnq = window._learnq || []
      window._learnq.push(['identify', {
        '$email' :  options.email
      }])
    } catch(e) { window.application.notify(e) }
  }

  static viewedProduct(options) {
    // console.log('Klaviyo viewedProduct ->', options)
    // return

    try {
      window._learnq = window._learnq || []
      var item = {
        "ProductName": options.name,
        "ProductID": options.slug,
        "SKU": options.skuvariation,
        "ImageURL": options.image,
        "URL": options.url,
        "Price": options.price
      }

      window._learnq.push(["track", "Viewed Product", item])

      window._learnq.push(["trackViewedItem", {
        "Title": item.ProductName,
        "ItemId": item.ProductID,
        "Categories": item.Categories,
        "ImageUrl": item.ImageURL,
        "Url": item.URL,
        "Metadata": {
          "Price": item.Price
        }
      }])
    } catch(e) { window.application.notify(e) }
  }

  static addedToCart(options, cart) {
    // console.log('Klaviyo addedToCart ->', options, cart)
    // return

    try {
      window._learnq = window._learnq || []
      window._learnq.push(["track", "Added to Cart", {
        "$value": options.price,
        "AddedItemProductName": options.name,
        "AddedItemProductID": options.slug,
        "AddedItemSKU": options.skuvariation,
        "AddedItemImageURL": options.image,
        "AddedItemURL": options.url,
        "AddedItemPrice": options.price,
        "AddedItemQuantity": 1,
        "cart": cart
        // "Items": [{
        //     "ProductID": options.slug,
        //     "SKU": options.sku,
        //     "ProductName": options.name,
        //     "Quantity": 1,
        //     "ItemPrice": options.price,
        //     "RowTotal": options.price,
        //     "ProductURL": options.url,
        //     "ImageURL": options.image,
        //   }
        // ]
      }])
    } catch(e) { window.application.notify(e) }
  }

  static initiateCheckout(checkoutUID, options) {
    // console.log('Klaviyo initiateCheckout ->', options)
    // return

    try {
      var itemNames = options.map(function(e) {
        return e.slug
      })
      var totalValue = 0
      var items = options.map(function(e) {
        totalValue += parseInt(e.europrice)
        const skuvariation = e.sku + (e.variation === "" ? "" : "-" + e.variation.toUpperCase())
        return {
          "ProductID": e.slug,
          "SKU": skuvariation,
          "ProductName": e.title_en,
          "Quantity": 1,
          "ItemPrice": e.europrice,
          "RowTotal": e.europrice,
          "ProductURL": e.url,
          "ImageURL": e.image
        }
      })

      window._learnq = window._learnq || []
      window._learnq.push(["track", "Started Checkout", {
         "$event_id": Date.now(),
         "$value": totalValue,
         "ItemNames": itemNames,
         "CheckoutUID": checkoutUID,
         "CheckoutURL": "https://www.mara.paris/checkout/" + checkoutUID,
         "Items": items,
         "cart": options
       }])
     } catch(e) { window.application.notify(e) }
  }

  static failedPayment(options) {
    // console.log('Klaviyo failedPayment ->', options)
    // return

    try {
      window._learnq = window._learnq || []
      window._learnq.push(["track", "Failed Payment", {
        "$email": options.email,
        "$value": options.amount,
        "PaymentProvider": options.provider
      }])
    } catch(e) { }
  }

  static placedOrder(options, extras) {
    // console.log('Klaviyo placedOrder ->', options)
    // return

    try {
      var itemNames = options.map(function(e) {
        return e.name
      })
      var items = options.map(function(e) {
        return {
          "ProductID": e.slug,
          "SKU": e.skuvariation,
          "ProductName": e.name,
          "Quantity": 1,
          "ItemPrice": e.price,
          "RowTotal": e.price,
          "ProductURL": e.url,
          "ImageURL": e.image
        }
      })

      window._learnq = window._learnq || []
      window._learnq.push(['identify', {
        '$email': extras.email,
        '$first_name': extras.firstname,
        '$last_name': extras.lastname
      }])

      window._learnq.push(["track", "Placed Order", {
         "$event_id": Date.now(),
         "$value": extras.total,
         "ItemNames": itemNames,
         "Items": items
       }])
     } catch(e) { window.application.notify(e) }
  }

}

export default Klaviyo
window.Klaviyo = Klaviyo
