import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {

  }

  connect() {
    this.alternates = {}

    let languageTags = document.querySelectorAll("link[rel='alternate']")
    languageTags.forEach(languageTag => {
      if (languageTag.hreflang == 'x-default') { return }
      this.alternates[languageTag.hreflang] = languageTag.href
    })

  }

  disconnect() {
    this.alternates = {}
  }

  languageChanged(event) {
    event.preventDefault()

    let destination = this.alternates[event.target.value]
    window.location.href = destination

  }

}
