import { Controller } from "stimulus"
import Swiper from 'swiper'
import { EffectFade, Autoplay } from 'swiper/modules'

export default class extends Controller {

  static targets = ["container"]

  connect() {
    this.swiperInstance = null
    this.swiperContainer = document.querySelector('[data-swiper-slideshow]')
    this.initializeSwiper()
  }

  disconnect() {
    this.swiperInstance.destroy()
  }

  initializeSwiper() {
    if (this.swiperContainer === null) { return }
    this.swiperInstance = new Swiper(this.swiperContainer, {
      modules: [EffectFade, Autoplay],
      effect: 'fade',
      speed: 800,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 2400,
        disableOnInteraction: false
      }
    })
  }

}