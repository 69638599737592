import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {

  static values = {
    dots: {type: Boolean, default: false},
    arrows: {type: Boolean, default: true}
  }

  connect() {
    this.slider = ''
    this.initializeSlider()
  }

  disconnect() {
    this.destroySlider()
    this.slider = ''
  }

  initializeSlider() {
    const controls = this.element.querySelector('.controls')
    const nav = this.element.querySelector('.nav')
    const slideshow = this.element.querySelector('.slideshow')
    let slides = slideshow.querySelectorAll('.slide').length

    this.slider = tns({
      mode: 'gallery',
      container: slideshow,
      items: 1,
      slideBy: 1,
      mouseDrag: true,
      edgePadding: 10,
      gutter: 1,
      autoplay: true,
      autoplayTimeout: 2500,
      autoplayButton: false,
      autoplayButtonOutput: false,
      controls: this.arrowsValue,
      nav: this.dotsValue,
      navPosition: "bottom",
      preventScrollOnTouch: "auto",
      swipeAngle: 30,
    })
  }

  destroySlider() {
    this.slider.destroy()
  }

}
