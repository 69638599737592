import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "container" ]

  connect() {
    try {
      let activeFilter = this.containerTarget.querySelector('.active').getBoundingClientRect()
      this.containerTarget.scrollLeft = activeFilter.x + (activeFilter.width/2) - (window.innerWidth/2)
    }
    catch(e) {

    }

    this.containerTarget.querySelectorAll('.filter').forEach(filter => {
      filter.classList.remove('invisible')
    })
  }

  disconnect() {

  }

}
