import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["original", "seeButton", "hideButton"]

  initialize() {

  }

  connect() {
  }

  disconnect() {

  }

  showOriginal() {
    this.originalTarget.classList.remove('hidden')
    this.seeButtonTarget.classList.add('hidden')
    this.hideButtonTarget.classList.remove('hidden')
  }

  hideOriginal() {
    this.originalTarget.classList.add('hidden')
    this.seeButtonTarget.classList.remove('hidden')
    this.hideButtonTarget.classList.add('hidden')
  }

}
