import { Controller } from "stimulus"
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

export default class extends Controller {

  connect() {
    this.swiperInstance = null
    this.swiperContainer = document.querySelector('[data-swiper-hero]')
    this.initializeSwiper()
  }

  disconnect() {
    this.swiperInstance.destroy()
  }

  initializeSwiper() {
    if (this.swiperContainer === null) { return }
    this.swiperInstance = new Swiper(this.swiperContainer, {
      modules: [Autoplay],
      loop: true,
      allowTouchMove: false,
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 0.1,
      autoplay: {
        delay: 1800,
        disableOnInteraction: false
      },
      breakpoints: {
        480: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        }
      }

    })
  }


}
