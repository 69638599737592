import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  disconnect() {
  }

  scroll() {
    let reviews = document.querySelector('#product-reviews')
    if (reviews) {
      let pos = this.offset(reviews)
      window.scroll({
        top: pos.top - 120,
        behavior: 'smooth'
      })
    }
  }

  offset(el) {
    let rect = el.getBoundingClientRect()
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

}