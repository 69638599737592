import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggleable", "overlay", "slideIn", "hoverContainer" ]

  connect() {
    // this.toggle()
  }

  disconnect() {

  }

  mouseenter(event) {
    let sub = event.currentTarget.querySelector('[data-menu-target]')
    sub.classList.remove('invisible')

    // Hover menu trick by Hakim El Hattab
    // https://www.youtube.com/watch?v=o0NtjY17v5w&t=944s
    let container = event.currentTarget
    let button = container.querySelector('.hoverButton').getBoundingClientRect()
    let content = container.querySelector('.hoverContent').getBoundingClientRect()
    let path = container.querySelector('path')

    let header = document.querySelector('header')
    if (header.classList.contains('is_transparent')) {
      header.classList.remove('header_transparent')
    }

    path.setAttribute('d', `
    M ${button.x + button.width} ${button.height/2}
    Q ${button.x + button.width} ${content.y},
      ${content.width} ${content.y}
    H ${content.x}
    Q ${button.x} ${content.y},
      ${button.x} ${button.height/2}
    V ${button.height}
    H ${button.x + button.width}
    Z`)
    // End Hover menu trick
  }

  mouseleave(event) {
    let header = document.querySelector('header')
    if (header.classList.contains('is_transparent')) {
      header.classList.add('header_transparent')
    }

    let sub = event.currentTarget.querySelector('[data-menu-target]')
    sub.classList.add('invisible')
    let container = event.currentTarget
    let path = container.querySelector('path')
    path.setAttribute('d', '')
  }

  toggle(event=null) {
    if (event) event.preventDefault()

    // Body
    document.body.classList.toggle('overflow-hidden')

    // Container
    this.toggleableTarget.classList.toggle('invisible')
    this.toggleableTarget.classList.toggle('pointer-events-none')

    // Overlay
    this.overlayTarget.classList.toggle('opacity-60')
    this.overlayTarget.classList.toggle('opacity-0')
    this.overlayTarget.classList.toggle('delay-200')

    // Contents
    this.slideInTarget.classList.toggle('left-0')
    this.slideInTarget.classList.toggle('-left-76')
  }

  close(event=null) {
    // if (event) event.preventDefault()

    // Body
    document.body.classList.remove('overflow-hidden')

    // Container
    this.toggleableTarget.classList.add('invisible')
    this.toggleableTarget.classList.add('pointer-events-none')

    // Overlay
    this.overlayTarget.classList.remove('opacity-60')
    this.overlayTarget.classList.add('opacity-0')
    this.overlayTarget.classList.add('delay-200')

    // Contents
    this.slideInTarget.classList.remove('left-0')
    this.slideInTarget.classList.add('-left-76')
  }

}
